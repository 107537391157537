import axiosClient from './axiosClient';

class CheckoutApi {
  getOrderSummary = ({ products }) => {
    const url = '/orders/summary';
    return axiosClient.post(url, { products });
  };

  createOrder = (params) => {
    const url = '/orders';
    return axiosClient.post(url, params);
  };

  // SECTION OTHER
  // - API support for tracking order page

  getOrderDetail = (payload) => {
    const { orderID, params } = payload;
    const url = `/orders/${orderID}`;
    return axiosClient.get(url, { params });
  };

  updatePayment = (payload) => {
    const { orderID, payment } = payload;
    const url = `/orders/${orderID}`;
    return axiosClient.post(url, { payment });
  };
}

const checkoutApi = new CheckoutApi();
export default checkoutApi;
