export const ACCEPT_MENU = ['/products/mugs', '/products/shirts', '/products/masks', '/products/posters', '/products/pet-bowls'];
export const DISPLAY_ACCEPT_MENU = {
  "/products/mugs": "Mugs",
  "/products/shirts": "Shirts",
  "/products/masks": "Masks",
  "/products/posters": "Posters",
  "/products/pet-bowls": "Pet Bowls",
}

export const MENU_DROPDOWN = {
  products: 'products',
  collections: 'collections',
}

export const TYPE_PRODUCT = {
  PatternGroup: 'PatternGroup',
  TextStyle: 'TextStyle',
  PersonalImage: 'PersonalImage',
}
