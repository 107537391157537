import { configureStore } from '@reduxjs/toolkit';
import StorageKeys from 'constants/storage-keys';

import commonReducer from './commonSlice';
import homeReducer from 'features/triip-home/homeSlice';
import productsReducer from 'features/products/productsSlice';
import checkoutReducer from 'features/checkout/checkoutSlice';
import buyNowReducer from 'features/buy-now/buyNowSlice';
import trackingOrderReducer from 'features/tracking-order/trackingOrderSlice';

const store = configureStore({
  reducer: {
    common: commonReducer,
    home: homeReducer,
    products: productsReducer,
    checkout: checkoutReducer,
    buyNow: buyNowReducer,
    tracking: trackingOrderReducer,
  },
});

store.subscribe(() => {
  // TODO: Decide to use redux-persist or not later all
  // Now we only save area key
  const currentState = store.getState();
  localStorage.setItem(StorageKeys.CHECKOUT_REDUCER, JSON.stringify(currentState.checkout));
  localStorage.setItem(StorageKeys.BUY_NOW_REDUCER, JSON.stringify(currentState.buyNow));
})

export default store;