import Images from 'constants/image';
import React from 'react';
import './Page404.scss';

Page404Module.propTypes = {};

function Page404Module(props) {
  return (
    <div className="page-404">
      <div className="page-404-box">
        <div className="page-404-box__title">The triip you're looking for is not available.</div>
        <img
          className="page-404-box__logo"
          src={Images.ICON_404}
          alt=""
        />
      </div>
    </div>
  );
}

export default Page404Module;
