import logo from "assets/images/triip_tiim_logo.png";
import icon404 from "assets/images/404.png";

// Social - Store
import appleStore from "assets/images/appstore.png";
import googleStore from "assets/images/google-play.png";
import instagramIcon from "assets/images/instagram.png";
import facebookIcon from "assets/images/facebook-ico.png";
import linkedInIcon from "assets/images/linked-in-ico.png";
import tIcon from "assets/images/t_ico_17x17.png";
import twitterIcon from "assets/images/twitter-ico.png";
import youtubeIcon from "assets/images/youtube.png";

// How It Work
import growTravelSlice from "assets/images/how-it-work/grow-travel.png";
import comparePriceSlice from "assets/images/how-it-work/compare-price.png";
import travelHeroSlice from "assets/images/how-it-work/travel-hero.png";

// Product
import lineTopProduct from "assets/images/product/line-product-top.png";
import lineBottomProduct from "assets/images/product/line-product-bottom.png";
import sales from "assets/images/product/sales.png";
import salesRed from "assets/images/product/sales-red.png";
import productSlice01 from "assets/images/product/1.png";
import productSlice02 from "assets/images/product/2.png";
import productSlice03 from "assets/images/product/3.png";
import productDetailSlice from "assets/images/product/detail.png";
import productThumbSlice01 from "assets/images/product/thumb-1.png";
import productThumbSlice02 from "assets/images/product/thumb-2.png";

// Shape
import circleShape from "assets/images/shape/circle.png";
import ellipseShape from "assets/images/shape/ellipse.png";
import heartShape from "assets/images/shape/heart.png";
import rectangleShape from "assets/images/shape/rectangle.png";
import squareShape from "assets/images/shape/square.png";
import triangleShape from "assets/images/shape/triangle.png";

// Common
import bannerSlice from "assets/images/banner.png";
import ads from "assets/images/ads.png";
import cartSilverIcon from "assets/images/cart-silver.png";

// Logo
import cnnLogo from "assets/images/logo/Color/cnn_logo@2x.png";
import cnnLogoSilver from "assets/images/logo/Grey/cnn_logo@2x.png";
import nikkeiLogo from "assets/images/logo/Color/nikkei_logo@2x.png";
import nikkeiLogoSilver from "assets/images/logo/Grey/nikkei_logo@2x.png";
import tiaLogo from "assets/images/logo/Color/tia_logo@2x.png";
import tiaLogoSilver from "assets/images/logo/Grey/tia_logo@2x.png";
import e27Logo from "assets/images/logo/Color/e27@2x.png";
import e27LogoSilver from "assets/images/logo/Grey/e27@2x.png";
import ttgLogo from "assets/images/logo/Color/ttg_logo@2x.png";
import ttgLogoSilver from "assets/images/logo/Grey/ttg_logo@2x.png";
import badcreditLogo from "assets/images/logo/Color/badcredit@2x.png";
import badcreditLogoSilver from "assets/images/logo/Grey/badcredit@2x.png";

// Tools
import arrowDownTool from "assets/images/tools/arrow-down.svg";
import arrowUpTool from "assets/images/tools/arrow-up.svg";
import arrowLeftTool from "assets/images/tools/arrow-left.svg";
import arrowRightTool from "assets/images/tools/arrow-right.svg";
import rotateTool from "assets/images/tools/rotate.svg";
import zoomInTool from "assets/images/tools/zoom-in.svg";
import zoomOutTool from "assets/images/tools/zoom-out.svg";


const Images = {
  LOGO: logo,
  ICON_404: icon404,

  // Social - Store
  APPLE_STORE: appleStore,
  GOOGLE_STORE: googleStore,
  INSTAGRAM_ICON: instagramIcon,
  FACEBOOK_ICON: facebookIcon,
  LINKEDIN_ICON: linkedInIcon,
  T_ICON: tIcon,
  TWITTER_ICON: twitterIcon,
  YOUTUBE_ICON: youtubeIcon,

  // How It Work
  GROW_TRAVEL_SLICE: growTravelSlice,
  COMPARE_PRICE_SLICE: comparePriceSlice,
  TRAVEL_HERO_SLICE: travelHeroSlice,

  // Product
  LINE_TOP_PRODUCT: lineTopProduct,
  LINT_BOTTOM_PRODUCT: lineBottomProduct,
  SALES: sales,
  SALES_RED: salesRed,
  DUMMY_PRODUCT_01: productSlice01,
  DUMMY_PRODUCT_02: productSlice02,
  DUMMY_PRODUCT_03: productSlice03,
  DUMMY_PRODUCT_DETAIL_01: productDetailSlice,
  DUMMY_PRODUCT_THUMB1_01: productThumbSlice01,
  DUMMY_PRODUCT_THUMB1_02: productThumbSlice02,

  // Shape
  CIRCLE_SHAPE: circleShape,
  ELLIPSE_SHAPE: ellipseShape,
  HEART_SHAPE: heartShape,
  RECTANGLE_SHAPE: rectangleShape,
  SQUARE_SHAPE: squareShape,
  TRIANGLE_SHAPE: triangleShape,

  // Common
  BANNER_SLICE: bannerSlice,
  ADS: ads,
  CART_SILVER_ICON: cartSilverIcon,

  // Logo
  CNN_LOGO: cnnLogo,
  CNN_LOGO_SILVER: cnnLogoSilver,
  NIKKEI_LOGO: nikkeiLogo,
  NIKKEI_LOGO_SILVER: nikkeiLogoSilver,
  TIA_LOGO: tiaLogo,
  TIA_LOGO_SILVER: tiaLogoSilver,
  E27_LOGO: e27Logo,
  E27_LOGO_SILVER: e27LogoSilver,
  TTG_LOGO: ttgLogo,
  TTG_LOGO_SILVER: ttgLogoSilver,
  BADCREDIT_LOGO: badcreditLogo,
  BADCREDIT_LOGO_SILVER: badcreditLogoSilver,

  // Tools
  ARROW_DOWN_TOOL :arrowDownTool,
  ARROW_UP_TOOL :arrowUpTool,
  ARROW_LEFT_TOOL :arrowLeftTool,
  ARROW_RIGHT_TOOL :arrowRightTool,
  ROTATE_TOOL :rotateTool,
  ZOOM_IN_TOOL :zoomInTool,
  ZOOM_OUT_TOOL :zoomOutTool,
};

export default Images;
