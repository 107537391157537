import { Col, Dropdown, Input, Menu, Row } from 'antd';
import { getAllCategories, getAllCollections } from 'app/commonSlice';
import RoutingCheckout from 'features/checkout/routes';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import Routing from 'routes';
import useDeviceDetect from 'utils/useDeviceDetect';
import './Header.scss';

const { Search } = Input;

function Header(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const settings = useSelector(state => state.common.settings);
  const categories = useSelector(state => state.common.categories);
  const collections = useSelector(state => state.common.collections);
  const checkout = useSelector((state) => state.checkout.list);

  const [hoverState, setHoverState] = useState(window.innerWidth < 991);
  const searchContainerRef = useRef(null);
  const { isMobile } = useDeviceDetect();

  const acceptLocationsForNewHeader = [Routing.BUY_NOW.path, RoutingCheckout.CHECKOUT.path];
  const isNewHeaderForMobile = acceptLocationsForNewHeader.includes(location.pathname);

  /*------------------------------------------------------*/
  /*------------------ LIFE CYCLE HOOKS ------------------*/
  /*------------------------------------------------------*/

  // TODO:
  // - Get all categories && collections and decide what we will do
  //
  // SPECS:
  // - categories && collections will show in layout
  // - Should hardcode parent menu [Categgories & Collections] and sub-menu based on API to show
  useEffect(() => {
    dispatch(getAllCategories());
    dispatch(getAllCollections());
  }, [dispatch]);

  // TODO:
  // - Action handle click outside search component
  useEffect(() => {
    const clickOutsideListener = (event) => {
      if (window.innerWidth < 768) return;
      if (!searchContainerRef.current || searchContainerRef.current.contains(event.target)) return;

      setHoverState(false);
    };

    // In case popup if not mounted, don't forget to remove the click listener if any
    const cleanUp = () => window.removeEventListener('click', clickOutsideListener);

    // Only add click listener after the modal is mounted
    window.addEventListener('click', clickOutsideListener);
    return cleanUp;
  }, []);


  /*-----------------------------------------------------------*/
  /*------------------ FUNCTION HANDLE LOGIC ------------------*/
  /*-----------------------------------------------------------*/

  const handleSearch = value => {
    if (value === '') {
      setHoverState(true);

      setTimeout(() => {
        document.getElementById('input-search').focus();
      }, 100);
      return;
    }

    // Redirect to search page for handle
    history.push(Routing.SEARCH.path, {
      paramSearch: value,
    });
  }

  const checkLinkActived = (match, location) => {
    if (!match) {
      return false;
    }

    return match.url === location.pathname;
  };


  /*--------------------------------------------*/
  /*------------------ LAYOUT ------------------*/
  /*--------------------------------------------*/

  const menu = (
    <div className="menu-mobile">
      <h3>Categories</h3>
      <Menu style={{ width: '200px', marginBottom: '10px' }}>
        {categories && categories.map(route => {
          return (
            <Menu.Item key={`key-${route.slug}`}>
              <NavLink to={`/products/${route.slug}`} isActive={(match, location) => checkLinkActived(match, location)}>
                {route.name}
              </NavLink>
            </Menu.Item>
          )
        })}
      </Menu>

      <h3>Collections</h3>
      <Menu style={{ width: '200px', marginBottom: '10px' }}>
        {collections && collections.map(route => {
          return (
            <Menu.Item key={`key-${route.slug}`}>
              <NavLink to={`/collections/${route.slug}`} isActive={(match, location) => checkLinkActived(match, location)}>
                {route.title}
              </NavLink>
            </Menu.Item>
          )
        })}
      </Menu>
    </div>
  );

  const menuCategories = (
    <Menu style={{ width: '200px', marginTop: '-25px' }}>
      {categories && categories.map(route => {
        return (
          <Menu.Item key={`key-${route.slug}`}>
            <NavLink to={`/products/${route.slug}`} isActive={(match, location) => checkLinkActived(match, location)}>
              {route.name}
            </NavLink>
          </Menu.Item>
        )
      })}
    </Menu>
  );

  const menuCollections = (
    <Menu style={{ width: '200px', marginTop: '-25px' }}>
      {collections && collections.map(route => {
        return (
          <Menu.Item key={`key-${route.slug}`}>
            <NavLink to={`/collections/${route.slug}`} isActive={(match, location) => checkLinkActived(match, location)}>
              {route.title}
            </NavLink>
          </Menu.Item>
        )
      })}
    </Menu>
  );

  return (
    <>
      <Helmet>
        <title>{settings ? settings.shop_name : 'Triip Store - Website'}</title>
        <meta charSet="utf-8" />
        {settings && <meta name="description" content={settings.shop_name} />}
      </Helmet>

      {(isNewHeaderForMobile === true && isMobile === true) && (
        <Row align="middle" className="order-header">
          <a onClick={() => history.goBack()}>
            <i className="material-icons">keyboard_arrow_left</i>
          </a>

          <span>Order</span>
        </Row> 
      )}

      {(isNewHeaderForMobile === false || isMobile === false) && (
        <Row align="middle">
          <Col className="logo">
            <NavLink to={Routing.TRIIP_HOME.path} title={settings ? settings.shop_name : ''}>
              {settings && settings.shop_logo ? <img src={settings.shop_logo} alt={settings ? settings.shop_name : ''} alt="triip me logo" height="65" /> : null }
            </NavLink>
          </Col>

          <Col className="desktop-view flex-one">
            <div className="header-menu">
              <Dropdown overlay={menuCategories} trigger={['hover']}>
                <a className="ant-dropdown-link">
                  <label>Categories</label>
                  <span className="material-icons">expand_more</span>
                </a>
              </Dropdown>

              <Dropdown overlay={menuCollections} trigger={['hover']}>
                <a className="ant-dropdown-link">
                  <label>Collections</label>
                  <span className="material-icons">expand_more</span>
                </a>
              </Dropdown>
            </div>
          </Col>

          <Col className="desktop-view register-login" style={{ width: '220px' }}>
            <div ref={searchContainerRef} className={`triip-v3-non-search collapse ${hoverState ? 'active ' : 'hidden-input'}`}>
              <Search
                id="input-search"
                placeholder="Personalized Ornament, Dog Mom Shirt, Ornaments, Pillow"
                onSearch={handleSearch}
                style={{ width: hoverState ? 180 : 38 }}
              />
            </div>

            <button className="triip-v3-cart-btn">
              <NavLink to={RoutingCheckout.CHECKOUT.path}>
                <i className="material-icons">shopping_cart</i>
              </NavLink>

              {checkout && checkout.length > 0 && (
                <span>{checkout.length}</span>
              )}
            </button>
          </Col>

          {/* Dropdown for mobile */}
          {categories && categories.length > 0 && (
            <Col span={24} className="mobile-view header-menu-mobile">
              <Dropdown overlay={menu} trigger={['click']}>
                <a className="ant-dropdown-link">
                  <span className="material-icons">menu</span>
                </a>
              </Dropdown>
            </Col>
          )}
        </Row>
      )}
    </>
  );
}

Header.propTypes = {};

export default Header;
