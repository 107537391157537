
const StorageKeys = {
  ACCESS_TOKEN: 'access_token',
  LANGUAGE_TOKEN: 'language_token',

  CONFIG_REDUCER: 'config_reducer',
  CHECKOUT_REDUCER: 'checkout_reducer',
  BUY_NOW_REDUCER: 'buy_now_reducer',
};

export default StorageKeys;