import Images from 'constants/image';
import React from 'react';
import './Loading.scss';

Loading.propTypes = {};

function Loading() {
  return (
    <div className="loading">
      <div className="loading__circle">
        <img className="loading__icon" src={Images.LOGO} alt="company icon" />
      </div>
    </div>
  );
}

export default Loading;