import axiosClient from './axiosClient';

class ProductApi {
  getProductByOrder = (params) => {
    const url = '/products';
    return axiosClient.get(url, { params });
  };

  getDetailProduct = (payload) => {
    const { id, params } = payload;
    const url = `/products/${id}`;
    return axiosClient.get(url, { params });
  };
}

const productApi = new ProductApi();
export default productApi;
