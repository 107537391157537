import PrivateRoute from 'components/PrivateRoute';
import PropTypes from 'prop-types';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Page404Module from 'components/Page404';

RouteList.propTypes = {
  routes: PropTypes.object,
};

RouteList.defaultProps = {
  routes: {}
};

function RouteList(props) {
  const { routes } = props;
  const routeList = Object.keys(routes).map(key => routes[key]);
  if (routeList.length === 0) return null;

  return (
    <Switch>
      {routeList.map(route => (
        route.isPrivate
          ? (
            <PrivateRoute
              key={route.path}
              exact={route.exact}
              path={route.path}
              component={route.component}
            />
          )
          : (
            <Route
              key={route.path}
              exact={route.exact}
              path={route.path}
              component={route.component}
            />
          )
      ))}
      <Route component={Page404Module} />
    </Switch>
  );
}

export default RouteList;