/**
 * API Gateway for this App
 *
 * - All http requests must go through this gateway
 * - This client will add stuffs for every requests like headers, baseURL, ...
 * - This is also a good place to handle errors as captured in interceptors.
 */

import axios from 'axios';
import StorageKeys from 'constants/storage-keys';
import queryString from 'query-string';
import { toast } from 'react-toastify';

// Set up default config for http requests here
// Please have a look at here `https://github.com/axios/axios#request-config` for the full list of configs
const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'content-type': 'application/json',
  },
  paramsSerializer: params => queryString.stringify(params),
});

axiosClient.interceptors.request.use(async (config) => {
  // Attach auth header using token from local storage
  const accessToken = localStorage.getItem(StorageKeys.ACCESS_TOKEN);
  if (accessToken) {
    config.headers.Authorization = accessToken;
  }

  // All request need pass language - we use token from local storage
  const languageCode = localStorage.getItem(StorageKeys.LANGUAGE_TOKEN) || 'en';
  config.headers['accept-language'] = languageCode;

  return config;
})

axiosClient.interceptors.response.use((response) => {
  if (response && response.data) {
    return response.data;
  }

  return response;
}, (error) => {
  // Handle errors
  const errorMessage = error.response.data
    ? error.response.data.message
    : error.response.data;

  // Default case show error
  if (error.response && error.response.status === 400) {
    toast.error(JSON.stringify(errorMessage));
  }

  throw new Error(errorMessage);
});

export default axiosClient;
