import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import productApi from 'api/productApi';
import { MENU_DROPDOWN } from 'constants/common';

export const getProductDetail = createAsyncThunk('products/getProductDetail', async (params, thunkAPI) => {
  try {
    const response = await productApi.getDetailProduct(params);
    return response.data || {};
  } catch (error) {
    console.log('failed to load product, reason:', error);
    return;
  }
});

export const getAllProductsOrder = createAsyncThunk('products/getAllProductsOrder', async (params, thunkAPI) => {
  try {
    const { source, type, sorted } = params;
    const response = {};

    await Promise.allSettled(
      source.map(async ({ id, name, slug }) => {
        const payload = {
          'page': 1,
          'per_page': 20,
        };

        if (type === MENU_DROPDOWN.products) {
          Object.assign(payload, {
            'filter[category_ids][]': id,
            sort: sorted,
          });
        } else {
          Object.assign(payload, {
            'filter[collection_ids][]': id,
            sort: sorted,
          });
        }
  
        const responseEachProduct = await productApi.getProductByOrder(payload);
        Object.assign(response, {
          [id]: {
            id,
            name,
            slug,
            source: responseEachProduct.data || []
          },
        });
      }),
    );
  
    return response || {};
  } catch (error) {
    console.log('failed to load product, reason:', error);
    return;
  }
});

const initialState = {
  list: {},
  result: {},
  loading: false,
  empty: false,
};

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    mergeParamProductByID: (state, action) => {
      const params = state.list;
      state.list = Object.assign(params[action.payload.id], {
        source: [...params[action.payload.id].source, action.payload.products]
      });
    },
  },
  extraReducers: {
    [getAllProductsOrder.pending]: (state, action) => {
      state.loading = true;
    },

    [getAllProductsOrder.fulfilled]: (state, action) => {
      state.loading = false;
      state.list = action.payload;
    },

    [getAllProductsOrder.rejected]: (state, action) => {
      state.loading = false;
      state.empty = true;
    },

    [getProductDetail.pending]: (state, action) => {
      state.loading = true;
    },

    [getProductDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.result = action.payload;
    },

    [getProductDetail.rejected]: (state, action) => {
      state.loading = false;
      state.empty = true;
    },
  },
});

const { actions, reducer: productsReducer } = productsSlice;
export const { mergeParamProductByID } = actions;
export default productsReducer;
