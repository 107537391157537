import React from 'react';

const Checkout = React.lazy(() => import('./pages/Checkout'));
const PaymentSuccess = React.lazy(() => import('./pages/PaymentSuccess'));
const BuyNowPaymentSuccess = React.lazy(() => import('./pages/BuyNowPaymentSuccess'));

const Routing = {
  CHECKOUT: { path: '/checkout/payment', component: Checkout, exact: true, isPrivate: false },
  PAYMENT_SUCCESS: { path: '/checkout/payment-success', component: PaymentSuccess, exact: true, isPrivate: false },
  BUY_NOW_PAYMENT_SUCCESS: { path: '/checkout/buy-now-payment-success', component: BuyNowPaymentSuccess, exact: true, isPrivate: false },
};

export default Routing;
