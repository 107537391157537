import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import StorageKeys from 'constants/storage-keys';
import checkoutApi from 'api/checkoutApi';

export const getOrderSummary = createAsyncThunk('checkout/getOrderSummary', async (params, thunkAPI) => {
  try {
    const response = await checkoutApi.getOrderSummary(params);
    return response.data || {};
  } catch (error) {
    console.log('failed to load order, reason:', error);
    return;
  }
});

const initialState = JSON.parse(localStorage.getItem(StorageKeys.CHECKOUT_REDUCER)) || {
  list: [],
  orderSummary: {},
  loading: false,
};

const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    addToCart: (state, action) => {
      state.list = action.payload;
    },
  },
  extraReducers: {
    [getOrderSummary.fulfilled]: (state, action) => {
      state.orderSummary = action.payload;
    },
  },
});

const { actions, reducer: checkoutReducer } = checkoutSlice;
export const { addToCart } = actions;
export default checkoutReducer;
