import axiosClient from './axiosClient';

class SettingsApi {
  getSettings = () => {
    const url = '/shop_settings';
    return axiosClient.get(url);
  };

  getBanners = () => {
    const url = '/banners';
    return axiosClient.get(url);
  };
}

const settingsApi = new SettingsApi();
export default settingsApi;
