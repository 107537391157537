import axiosClient from './axiosClient';

class CategoriesApi {
  getAllCategories = (params) => {
    const url = '/categories';
    return axiosClient.get(url, { params });
  };

  getAllCollections = (params) => {
    const url = '/collections';
    return axiosClient.get(url, { params });
  };
}

const categoriesApi = new CategoriesApi();
export default categoriesApi;
