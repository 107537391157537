// import StorageKeys from 'constants/storage-keys';
import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Routing from 'routes';

function PrivateRoute(props) {
  const { exact, path, component: Component } = props;

  return (
    <Route
      exact={exact}
      path={path}
      render={routeProps => {
        if (process.env.NODE_ENV === 'production') {
          return <Redirect to={Routing.TRIIP_HOME.path}/>;
        }

        return <Component {...routeProps} />;
      }}
    />
  );
}

PrivateRoute.propTypes = {
  exact: PropTypes.bool,
  path: PropTypes.string,
  component: PropTypes.any.isRequired,
};

PrivateRoute.defaultProps = {
  exact: false,
  path: '',
};

export default PrivateRoute;