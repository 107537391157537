/** @var {resources} */
const resources = {
  en: {
    translation: {
      common: require('./locales/common/en.json'),
      user: require('./locales/user/en.json'),
      form: require('./locales/form/en.json'),
    }
  },
  vi: {
    translation: {
      common: require('./locales/common/vi.json'),
      user: require('./locales/user/vi.json'),
      form: require('./locales/form/vi.json'),
    }
  },
}

export default resources
