import axiosClient from './axiosClient';

class FormApi {
  getAllCountries = (params) => {
    const url = '/countries';
    return axiosClient.get(url, { params });
  };

  getAllStates = (payload) => {
    const { countryID, params } = payload;
    const url = `/countries/${countryID}/states`;
    return axiosClient.get(url, { params });
  };

  getAllCities = (payload) => {
    const { countryID, stateID, params } = payload;
    const url = `/countries/${countryID}/states/${stateID}/cities`;
    return axiosClient.get(url, { params });
  };
}

const formApi = new FormApi();
export default formApi;
