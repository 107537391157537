import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import StorageKeys from 'constants/storage-keys';
import checkoutApi from 'api/checkoutApi';

export const getTrackingOrder = createAsyncThunk('tracking/getTrackingOrder', async (params, thunkAPI) => {
  try {
    const response = await checkoutApi.getOrderDetail(params);
    return response.data || {};
  } catch (error) {
    console.log('failed to load order, reason:', error);
    window.location.href = '/';
  }
});

const initialState = {
  result: {},
  loading: false,
};

const trackingOrderSlice = createSlice({
  name: 'tracking',
  initialState,
  reducers: {
    updateTrackingOrder: (state, action) => {
      state.result = action.payload;
    },
  },
  extraReducers: {
    [getTrackingOrder.pending]: (state, action) => {
      state.loading = true;
    },

    [getTrackingOrder.fulfilled]: (state, action) => {
      if (action.payload) {
        state.loading = false;
        state.result = action.payload;
      }
    },

    [getTrackingOrder.rejected]: (state, action) => {
      state.loading = true;
      state.empty = true;
    },
  },
});

const { actions, reducer: checkoutReducer } = trackingOrderSlice;
export const { updateTrackingOrder } = actions;
export default checkoutReducer;
