import { Col, Row } from 'antd';
import Images from 'constants/image';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import Routing from 'routes';
import './Footer.scss';

function Footer(props) {
  const dispatch = useDispatch();

  const settings = useSelector(state => state.common.settings);

  const aboutUs = [
    {
      name: 'About Us',
      targetBlank: false,
      goTo: '/pages/about-us'
    },
    {
      name: 'Contact',
      targetBlank: false,
      goTo: '/pages/contact-us'
    },
  ];

  const commonItems = [
    {
      name: 'FAQs',
      targetBlank: false,
      goTo: '/pages/faqs'
    },
    {
      name: 'Order Tracking',
      targetBlank: false,
      goTo: '/tracking-order'
    },
    {
      name: 'Size Chart',
      targetBlank: false,
      goTo: '/pages/contact-us'
    },
  ];
  
  const productsItems = [
    {
      name: 'Privacy Policies',
      targetBlank: false,
      goTo: '/pages/privacy-policy'

    },
    {
      name: 'Shipping Policy',
      targetBlank: true,
      goTo: '/pages/booking-policy'
    },
    {
      name: 'Terms of Service',
      targetBlank: false,
      goTo: '/pages/terms-of-service'
    },
    {
      name: 'Replacement & Refund Policy',
      targetBlank: false,
      goTo: '/pages/terms-of-service'
    },
  ];

  return (
    <Row className="footer">
      <Col xs={24} sm={24} md={6}>
        <div className="footer__brand">
          <NavLink to={Routing.TRIIP_HOME.path} title={settings ? settings.shop_name : ''}>
            {settings && settings.shop_logo ? <img src={settings.shop_logo} alt={settings ? settings.shop_name : ''} alt="triip me logo" height="65" /> : null }
          </NavLink>
          <p />
          <p>© 2020 {settings ? settings.shop_name : ''}. All rights reserved.</p>
        </div>
      </Col>

      <Col xs={12} sm={12} md={6}>
        <div className="footer__block">
          <h3>About</h3>

          <ul className="footer__block-nav">
            {aboutUs.map(item => (
              <li key={`item-${item.name}`}>
                {item.targetBlank === true
                  ? (
                    <a target="_blank" rel="noopener noreferrer" href={item.goTo}>
                      {item.name}
                    </a>
                  ) : (
                    <Link to={item.goTo}>
                      {item.name}
                    </Link>
                  )
                }
              </li>
            ))}
          </ul>
        </div>
      </Col>

      <Col xs={12} sm={12} md={6}>
        <div className="footer__block">
          <h3>Our Policy</h3>
          <ul className="footer__block-nav">
            {productsItems.map((item, i) => {       
              return (
                <li key={`item-${item.name}`}>
                  {item.targetBlank === true
                    ? (
                      <a target="_blank" rel="noopener noreferrer" href={item.goTo}>
                        {item.name}
                      </a>
                    ) : (
                      <Link to={item.goTo}>
                        {item.name}
                      </Link>
                    )
                  }
                </li>
              );
            })}
          </ul>
        </div>

        {/* {<div className="footer__block">
          <h3>Follow us on</h3>
          <ul className="footer__block-social">
            <li>
              <a href="https://www.facebook.com/triip.me/" target="_blank" title="facebook">
                <img alt="Social" src={Images.FACEBOOK_ICON} />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/triipme" target="_blank" title="twitter">
                <img alt="Social" src={Images.TWITTER_ICON} />
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/triipme" target="_blank" title="linkedin">
                <img alt="Social" src={Images.LINKEDIN_ICON} />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/triipme/" target="_blank" title="instagram">
                <img alt="Social" src={Images.INSTAGRAM_ICON} />
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/channel/UCu-2qzWZqQOTJJQVncJ3toA/" target="_blank" title="youtube">
                <img alt="Social" src={Images.YOUTUBE_ICON} />
              </a>
            </li>
            <li>
              <a href="https://t.me/triipmilesICO" target="_blank" title="Telegram">
                <img alt="Social" src={Images.T_ICON} />
              </a>
            </li>
          </ul>
        </div>} */}
      </Col>

      <Col xs={24} sm={24} md={6}>
        <div className="footer__block">
          <h3>Help</h3>

          <ul className="footer__block-nav">
            {commonItems.map((item, i) => {       
              return (
                <li key={`item-${item.name}`}>
                  {item.targetBlank === true
                    ? (
                      <a target="_blank" rel="noopener noreferrer" href={item.goTo}>
                        {item.name}
                      </a>
                    ) : (
                      <Link to={item.goTo}>
                        {item.name}
                      </Link>
                    )
                  }
                </li>
              );
            })}
          </ul>
          {/* {<ul className="footer__block-linkapp">
            <li>
              <a href="https://link.triip.me/paJG79NjP3" title="Appstore">
                <img src={Images.APPLE_STORE} title="App store" />
              </a>
            </li>
            <li>
              <a href="https://link.triip.me/X4h9PHpjP3" title="Google Play">
                <img src={Images.GOOGLE_STORE} title="Google Play" />
              </a>
            </li>
          </ul>} */}
        </div>
      </Col>
    </Row>
  );
}

Footer.propTypes = {};

export default Footer;
