import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import StorageKeys from "constants/storage-keys";

import categoriesApi from 'api/categoriesApi';
import formApi from 'api/formApi';
import settingsApi from 'api/settingsApi';

export const getAllStatesBilling = createAsyncThunk('common/getAllStatesBilling', async (params, thunkAPI) => {
  try {
    const response = await formApi.getAllStates(params);
    return response.data || [];
  } catch (error) {
    console.log('failed to load States, reason:', error);
    return;
  }
});

export const getAllCitiesBilling = createAsyncThunk('common/getAllCitiesBilling', async (params, thunkAPI) => {
  try {
    const response = await formApi.getAllCities(params);
    return response.data || [];
  } catch (error) {
    console.log('failed to load Cities, reason:', error);
    return;
  }
});

export const getAllStates = createAsyncThunk('common/getAllStates', async (params, thunkAPI) => {
  try {
    const response = await formApi.getAllStates(params);
    return response.data || [];
  } catch (error) {
    console.log('failed to load States, reason:', error);
    return;
  }
});

export const getAllCities = createAsyncThunk('common/getAllCities', async (params, thunkAPI) => {
  try {
    const response = await formApi.getAllCities(params);
    return response.data || [];
  } catch (error) {
    console.log('failed to load Cities, reason:', error);
    return;
  }
});

export const getAllCountries = createAsyncThunk('common/getAllCountries', async (params, thunkAPI) => {
  try {
    const response = await formApi.getAllCountries(params);
    return response.data || [];
  } catch (error) {
    console.log('failed to load Countries, reason:', error);
    return;
  }
});

export const getSettings = createAsyncThunk('common/getSettings', async (params, thunkAPI) => {
  try {
    const response = await settingsApi.getSettings();
    return response.data || {};
  } catch (error) {
    throw error;
  }
});

export const getAllCategories = createAsyncThunk('common/getAllCategories', async (params, thunkAPI) => {
  try {
    const response = await categoriesApi.getAllCategories();
    return response.data || [];
  } catch (error) {
    throw error;
  }
});

export const getAllCollections = createAsyncThunk('common/getAllCollections', async (params, thunkAPI) => {
  try {
    const response = await categoriesApi.getAllCollections();
    return response.data || [];
  } catch (error) {
    throw error;
  }
});

const initialState = JSON.parse(localStorage.getItem(StorageKeys.CONFIG_REDUCER)) || {
  loading: false,
  languageCode: 'en',
  settings: {},
  categories: [],
  collections: [],

  countriesForm: [],
  statesForm: [],
  citiesForm: [],

  countriesBillingForm: [],
  statesBillingForm: [],
  citiesBillingForm: [],
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setGlobalLoading: (state, action) => {
      const showLoading = action.payload;
      state.loading = showLoading;
    },
  },
  extraReducers: {
    [getSettings.fulfilled]: (state, action) => {
      state.settings = action.payload;
    },
    [getAllCategories.fulfilled]: (state, action) => {
      state.categories = action.payload;
    },
    [getAllCollections.fulfilled]: (state, action) => {
      state.collections = action.payload;
    },

    [getAllCountries.fulfilled]: (state, action) => {
      state.countriesForm = action.payload;
    },
    [getAllStates.fulfilled]: (state, action) => {
      state.statesForm = action.payload;
    },
    [getAllCities.fulfilled]: (state, action) => {
      state.citiesForm = action.payload;
    },
    [getAllStatesBilling.fulfilled]: (state, action) => {
      state.statesBillingForm = action.payload;
    },
    [getAllCitiesBilling.fulfilled]: (state, action) => {
      state.citiesBillingForm = action.payload;
    },
  },
});

const { reducer: commonReducer, actions } = commonSlice;
export const { setGlobalLoading } = actions;
export default commonReducer;
