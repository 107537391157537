import { Layout } from 'antd';
import 'antd/dist/antd.css';
import { getSettings } from 'app/commonSlice';
import FooterModule from 'components/Footer';
import HeaderModule from 'components/Header';
import Loading from 'components/Loading';
import RouteList from 'components/RouteList';
import { addToCartBuyNow, resetOrderSummary } from 'features/buy-now/buyNowSlice';
import RoutingCheckout from 'features/checkout/routes';
import React, { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Routing from 'routes';
import './App.scss';
const { Header, Footer, Content } = Layout;

function App() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const showGlobalLoading = useSelector(state => state.common.loading);
  const buyNow = useSelector(state => state.buyNow.list);

  const acceptLocationsForNewHeader = [Routing.BUY_NOW.path, RoutingCheckout.CHECKOUT.path];
  const isNewHeader = acceptLocationsForNewHeader.includes(location.pathname);

  /*------------------------------------------------------*/
  /*------------------ LIFE CYCLE HOOKS ------------------*/
  /*------------------------------------------------------*/

  // TODO:
  // - Get settings
  useEffect(() => {
    dispatch(getSettings());
  }, [dispatch]);

  useEffect(() => {
    let scrollPos = 0;
    const headerElement = document.getElementsByClassName('app__header');
    const getFirstHeaderElement = headerElement[0];
    
    const onScroll = () => {
      const { pathname } = history.location;
      const acceptScroll = pathname.split('/').length <= 2 || pathname.search('pages') !== -1;

      if (acceptScroll === false) {
        scrollPos = 0;
        document.getElementsByClassName('app__header')[0].classList.remove('fixed');
      }

      if (window.innerWidth > 992 && acceptScroll === true) {
        if ((document.body.getBoundingClientRect()).top > scrollPos && window.scrollY > getFirstHeaderElement.clientHeight + 50) {
          document.getElementsByClassName('app__header')[0].classList.add('fixed');
        } else {
          if (window.scrollY >= window.innerHeight) {
            document.getElementsByClassName('app__header')[0].classList.add('fixed');
          } else {
            document.getElementsByClassName('app__header')[0].classList.remove('fixed');
          }
        }
  
        // saves the new position for iteration.
        scrollPos = (document.body.getBoundingClientRect()).top;
      }

      if (window.innerWidth < 991) {
        if (window.scrollY >= 75) {
          document.getElementsByClassName('app__header')[0].classList.add('fixed');
        } else {
          document.getElementsByClassName('app__header')[0].classList.remove('fixed');
        }
      }
    };

    // It's still lag browser when we use multiple detect onScroll =))
    // Make sure I can resolve this issue before run code again
    onScroll();
    window.addEventListener("scroll", onScroll);
  
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  // TODO:
  // - Clear all buy-now if current page user access not [Buy Now]
  useEffect(() => {
    if (location.pathname !== Routing.BUY_NOW.path && buyNow && buyNow.length > 0) {
      dispatch(addToCartBuyNow([]));
      dispatch(resetOrderSummary({}));
    }
  }, [location.pathname, dispatch]);

  /*--------------------------------------------*/
  /*------------------ LAYOUT ------------------*/
  /*--------------------------------------------*/

  return (
    <Layout className="app">
      <Header className={`app__header ${isNewHeader === true ? 'app__header--new' : ''}`}>
        <div className="app__header--wrap">
          <HeaderModule routes={Routing} />
        </div>
      </Header>

      <Content className={`app__main ${isNewHeader === true ? 'app__main--new' : ''}`}>
        {showGlobalLoading && <Loading />}

        <Suspense fallback={<Loading />}>
          <RouteList routes={Routing} />
        </Suspense>
      </Content>

      <Footer className="app__footer">
        <div className="app__footer--wrap">
          <FooterModule />
        </div>
      </Footer>

      <ToastContainer
        draggable
        closeOnClick
        pauseOnHover
        hideProgressBar
        pauseOnVisibilityChange
        position="top-center"
        autoClose={5000}
        newestOnTop={false}
        rtl={false}
      />
    </Layout>
  );
}

export default App;
