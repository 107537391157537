import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import productApi from 'api/productApi';
import settingsApi from 'api/settingsApi';

export const getAllProductsOrderByHome = createAsyncThunk('home/getAllProductsOrderByHome', async (source, thunkAPI) => {
  const response = {};
  await Promise.allSettled(
    source.map(async ({ id, name, slug }) => {
      const params = {
        'page': 1,
        'per_page': 4,
        'filter[category_ids][]': id
      };

      const responseEachProduct = await productApi.getProductByOrder(params);
      Object.assign(response, {
        [id]: {
          id,
          name,
          slug,
          source: responseEachProduct.data || []
        },
      });
    }),
  );

  return response || {};
});

export const getBanners = createAsyncThunk('home/getBanners', async (params, thunkAPI) => {
  try {
    const response = await settingsApi.getBanners();
    return response.data || [];
  } catch (error) {
    console.log('failed to load product, reason:', error);
    return;
  }
});

const initialState = {
  productsByOrder: {},
  banners: [],
};

const homeSlice = createSlice({
  name: 'home',
  initialState,
  extraReducers: {
    [getAllProductsOrderByHome.fulfilled]: (state, action) => {
      state.productsByOrder = action.payload;
    },
    [getBanners.fulfilled]: (state, action) => {
      state.banners = action.payload;
    },
  },
});

const { reducer: homeReducer } = homeSlice;
export default homeReducer;
