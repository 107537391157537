import React from 'react';

// TriipHome
const TriipHomeModule = React.lazy(() => import('features/triip-home'));

// Product Pages
const ProducstModule = React.lazy(() => import('features/products'));

// Static Pages
const StaticPagesModule = React.lazy(() => import('features/static-pages'));

// Checkout Pages
const CheckoutModule = React.lazy(() => import('features/checkout'));

// Buy Now Pages
const BuyNowModule = React.lazy(() => import('features/buy-now'));

// Tracking Order Pages
const TrackingOrderModule = React.lazy(() => import('features/tracking-order'));

// Example
const ExampleModule = React.lazy(() => import('features/example'));

const Routing = {
  // TriipHomePage
  TRIIP_HOME: { path: '/', component: TriipHomeModule, exact: true, isPrivate: false },

  // Catogeries && Collections
  PRODUCTS: { path: '/products', component: ProducstModule, exact: false, isPrivate: false },
  PRODUCTS_COLLECTIONS: { path: '/collections', component: ProducstModule, exact: false, isPrivate: false },

  // Static Pages
  STATIC_PAGES: { path: '/pages', component: StaticPagesModule, exact: false, isPrivate: false },

  // Checkout Pages
  CHECKOUT: { path: '/checkout', component: CheckoutModule, exact: false, isPrivate: false },

  // Buy Now Pages
  BUY_NOW: { path: '/buy-now', component: BuyNowModule, exact: true, isPrivate: false },

  // Tracking Order Pages
  TRACKING_ORDER: { path: '/tracking-order', component: TrackingOrderModule, exact: false, isPrivate: false },

  // Example
  EXAMPLE: { path: '/example', component: ExampleModule, exact: false, isPrivate: true },
};

export default Routing;